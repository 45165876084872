.App {
  background-image: url('../public/bliss.png') ;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Optional - this will make the background fixed as the content scrolls */
  width: 100%;
  height: 100vh;
  overflow: auto;
  
  
}


.taskBar{
  background-color: #3571f3;
  background: linear-gradient(to bottom, #2051bb, #3571f3);
  height: 5vh;
  width: 100vw;
  position: fixed; /* Position the taskbar absolutely within the .App container */
  bottom: 0;
  border-radius: 5px;
}

.startButton{
  background-color: #33b84d;
  background: linear-gradient(to bottom, #33b84d, #43dd62);
  width: 8vw;
  height: 5vh;
  border-radius: 5px;
}

.homeIcon{
  width: 15vw;
}

.homeIconLink{
  text-decoration: none;
  width: 30vw;
  height: 30vh;
  
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: x-large;
}

.homeContainer{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homeIconsContainer{
  width: 80vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.ArtistsContainer{
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.indArtistContainer{
  height: 30vh;
  width: 30vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #c0c7c8;
  border-radius: 2px;
  border-style: solid;
  border-color: white black black white;
  border-width: 2px;
  
}

@font-face {
  font-family: 'Windows95';
  src: url('../public/W95FA.otf') format('truetype');
}

.artistTitleBar{
  background-color: #0000a8;
  width: 99%;
  margin: 2px;
  color: white;
  font-family: 'Windows95';
  height: 3vh;
  display: flex;
  align-items: center;
  padding-inline-start: 2px;
}

.artistTitle{
  font-size: xxx-large;
  font-family: 'Windows95';
  margin: 10px;
}

.artistButtons{
  background-color: #c0c7c8;
  border-radius: 2px;
  border-style: solid;
  border-color: white black black white;
  border-width: 2px;
  text-decoration: none;
  padding: 5px;
  margin-top: 3vh;
  color: black;
}


.backButton{
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
  width: 10vw;
}


.joggersContainer{
  height: 30vw;
  width: 30vw;
}

.joggersImg{
  max-width: 100%;
  display: none;
}

.merchMiddleContainer{
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  padding-top: 5vw;
  
}

.merchButtonsContainer{
  
  background-color: #c0c7c8;
  border-radius: 2px;
  border-style: solid;
  border-color: white black black white;
  border-width: 2px;
  height: 30vh;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.merchContainer{
  
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  
}

.dotsContainer{
  background-color: #0000a8;
  width: 99%;
  height: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline-start: 2px;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}
.nextButtonsContainer{
  width: 50%;
  margin-top: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev, .next {
  cursor: pointer;
  
  width: auto;
  padding: 16px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  margin: 0 2px;
}

.prev:hover, .next:hover {
  background-color: rgba(88, 88, 88, 0.8);
}

#product-component-1705415773325{
  /*min-width: 2000px;  Adjust the width based on available space and padding 
  background-color: #8888e99a;*/
}

.shopifyChromeTeeButtonContainer{
  
  width: 100%;
}

.ArtistsMiddleContainer{
  
}


@media (min-width: 800px){


  
  


.homeIconsContainer{
  width: 50vw;
}

.homeIcon{
  width: 5vw;
}

.backButton{
  width: 4vw;
}



}


@media (max-width: 800px){


  .shopifyChromeTeeButtonContainer{
  
    width: 80%;
    
  }

.startButton{
    width: 20vw;
}

.indArtistContainer{
  width: 70vw;
}



.merchMiddleContainer{
  width: 100%;
  height: 100%;
}
.joggersContainer{
  height: 50vh;
  width: 100vw;

}

.joggersImg{
  max-width: 100%;
  display: none;
}


.merchButtonsContainer{
  
  width: 80%;

}

}